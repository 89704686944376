import * as actionTypes from 'actions';

const initialState = {
  loading: false,
  reportEntries: [],
  lastUpdatedTime: null,
  mininumWalkCount: 50,
  deviceReportEntries: []
};

const customerEventMonitorListReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.CUSTOMER_EVENT_MONITOR_LIST_PENDING: {
      return {
        ...state,
        loading: true
      };
    }

    case actionTypes.CUSTOMER_EVENT_MONITOR_LIST_FULFILLED: {
      return {
        ...state,
        loading: false,
        reportEntries: action.payload.data.result,
        lastUpdatedTime: new Date().getTime()
      };
    }

    case actionTypes.CUSTOMER_EVENT_DEVICE_MONITOR_LIST_FULFILLED: {
      return {
        ...state,
        loading: false,
        deviceReportEntries: action.payload.data.result
      };
    }

    case actionTypes.CLEAR_CUSTOMER_EVENT_DEVICE_MONITOR_LIST: {
      return {
        ...state,
        deviceReportEntries: initialState.deviceReportEntries
      };
    }

    case actionTypes.SET_CUSTOMER_EVENT_MONITOR_LIST_MINIMUM_WALK_COUNT: {
      return {
        ...state,
        mininumWalkCount: action.payload
      };
    }

    default: {
      return state;
    }
  }
};

export default customerEventMonitorListReducer;
