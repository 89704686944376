import * as actionTypes from 'actions';

const initialState = {
  loading: false,
  cellLogEntryCounts: []
};

const monitorDeviceLogEntriesReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FIND_CELL_LOG_ENTRY_SUMMARY_COUNTS_PENDING: {
      return {
        ...state,
        loading: true,
        cellLogEntryCounts: []
      };
    }

    case actionTypes.FIND_CELL_LOG_ENTRY_SUMMARY_COUNTS_FULFILLED: {
      return {
        ...state,
        loading: false,
        cellLogEntryCounts: action.payload.data.result
      };
    }

    default: {
      return state;
    }
  }
};

export default monitorDeviceLogEntriesReducer;
