import * as actionTypes from 'actions';

const defaultSubjectAreaContent = {
  subjectArea: 'RIGHT_SIDE',
  pocketLocation: '',
  objectType: null,
  objectModel: null
};

const initialState = {
  customerEventLoading: false,
  updated: false,
  customerEvent: {
    createdBy: {},
    venue: {}
  },
  cellWalkCounts: [],
  cellWalkCountsLoading: false,
  entityPhotos: [],
  eventsLoading: false,
  validationEvents: {
    pageNumber: 1,
    rowCount: 0,
    loading: false,
    items: [],
    pageSize: 4
  },
  events: {
    pageNumber: 1,
    rowCount: 0,
    loading: false,
    items: [],
    pageSize: 250,
    filter: {
      objectPresent: false,
      objectNotPresent: false,
      columnSorts: [],
      specialInterest: null,
      predictionCorrect: null,
      predictionFalsePositive: null,
      predictionFalseNegative: null,
      dataPointId: '',
      fileName: '',
      objectPercent: [0, 0],
      objectLeftPercent: [0, 0],
      objectRightPercent: [0, 0]
    }
  },
  testingAnalysisOverview: {
    loading: false,
    analysisOverview: {},
    setupAnalysesCount: 0,
    eventCount: 0,
    noObjectEventCount: 0,
    locationEventCounts: {},
    subModels: []
  },
  cellAnalysis: {
    subFilterOverviews: [],
    subFilterTimeSeriesOverviews: []
  },
  commentsEvent: {
    comments: []
  },
  torsoThreshold: '',
  modelConfiguration: null,
  modelSet: null,
  cellEventTimes: {},
  cellEventTimesLoading: false,
  sessionGroups: [],
  cellMonitorings: [],
  cellMonitoringsLoading: false,
  objectTypes: [],
  excludedObjectTypes: [],
  eventCells: [],
  annotatedSubjectAreaContent: defaultSubjectAreaContent,
  annotatedSubjectAreaContents: [],
  annotatedSubjectAreaContentProcessing: false,
  weatherDataLoading: false,
  weatherData: [],
  cellHealthMeasuresLoading: false,
  cellHealthMeasures: [],
  thresholdBreaches: [],
  thresholdBreachesLoading: false,
  thresholdBreachSummaries: [],
  thresholdBreachSummariesLoading: false,
  healthTickets: [],
  healthTicketsLoading: false,
  deviceMonitoringReportEntries: [],
  deviceMonitoringReportEntriesLoading: false
};

const EVENT_LIST_PAGE = 'CUSTOMER_EVENT_DETAILS_EVENT_LIST';

const customerEventDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FIND_CUSTOMER_EVENT_PENDING: {
      return {
        ...initialState,
        customerEventLoading: true
      };
    }

    case actionTypes.FIND_CUSTOMER_EVENT_FULFILLED: {
      const customerEvent = action.payload.data.result;

      return {
        ...state,
        customerEvent: action.payload.data.result,
        customerEventLoading: false,
        annotatedSubjectAreaContents: customerEvent.annotatedSubjectAreaContents
          ? customerEvent.annotatedSubjectAreaContents
          : []
      };
    }

    case actionTypes.FIND_CUSTOMER_EVENT_CELL_WALK_COUNTS_PENDING: {
      return {
        ...initialState,
        cellWalkCountsLoading: true
      };
    }

    case actionTypes.FIND_CUSTOMER_EVENT_CELL_WALK_COUNTS_FULFILLED: {
      const eventCells = action.payload.data.result.map(c => ({
        id: c.cellId,
        name: c.cellName
      }));

      return {
        ...state,
        cellWalkCounts: action.payload.data.result,
        cellWalkCountsLoading: false,
        eventCells: eventCells
      };
    }

    case actionTypes.FIND_CUSTOMER_EVENT_WEATHER_DATA_PENDING: {
      return {
        ...state,
        weatherDataLoading: true
      };
    }

    case actionTypes.FIND_CUSTOMER_EVENT_WEATHER_DATA_FULFILLED: {
      return {
        ...state,
        weatherDataLoading: false,
        weatherData: action.payload.data.result
      };
    }

    case actionTypes.FIND_CUSTOMER_EVENT_CELL_HEALTH_MEASURES_PENDING: {
      return {
        ...state,
        cellHealthMeasuresLoading: true
      };
    }

    case actionTypes.FIND_CUSTOMER_EVENT_CELL_HEALTH_MEASURES_FULFILLED: {
      return {
        ...state,
        cellHealthMeasuresLoading: false,
        cellHealthMeasures: action.payload.data.result
      };
    }

    case actionTypes.FIND_CUSTOMER_EVENT_CELL_THRESHOLD_BREACHES_PENDING: {
      return {
        ...state,
        thresholdBreachesLoading: true
      };
    }

    case actionTypes.FIND_CUSTOMER_EVENT_CELL_THRESHOLD_BREACHES_FULFILLED: {
      return {
        ...state,
        thresholdBreachesLoading: false,
        thresholdBreaches: action.payload.data.result
      };
    }

    case actionTypes.FIND_CUSTOMER_EVENT_CELL_THRESHOLD_BREACH_SUMMARIES_PENDING: {
      return {
        ...state,
        thresholdBreachSummariesLoading: true
      };
    }

    case actionTypes.FIND_CUSTOMER_EVENT_CELL_THRESHOLD_BREACH_SUMMARIES_FULFILLED: {
      return {
        ...state,
        thresholdBreachSummariesLoading: false,
        thresholdBreachSummaries: action.payload.data.result
      };
    }

    case actionTypes.FIND_CUSTOMER_EVENT_CELL_HEALTH_TICKETS_PENDING: {
      return {
        ...state,
        healthTicketsLoading: true
      };
    }

    case actionTypes.FIND_CUSTOMER_EVENT_CELL_HEALTH_TICKETS_FULFILLED: {
      return {
        ...state,
        healthTicketsLoading: false,
        healthTickets: action.payload.data.result
      };
    }

    case actionTypes.RESET_CUSTOMER_EVENT_ANNOTATED_SUBJECT_AREA_CONTENT: {
      return {
        ...state,
        annotatedSubjectAreaContent: {
          ...defaultSubjectAreaContent
        }
      };
    }

    case actionTypes.RESET_CUSTOMER_EVENT_ANNOTATED_SUBJECT_AREA_CONTENTS: {
      return {
        ...state,
        annotatedSubjectAreaContents: state.customerEvent
          .annotatedSubjectAreaContents
          ? state.customerEvent.annotatedSubjectAreaContents.slice()
          : []
      };
    }

    case actionTypes.ADD_CUSTOMER_EVENT_ANNOTATED_SUBJECT_AREA_CONTENT: {
      const annotatedSubjectAreaContents = state.annotatedSubjectAreaContents.slice();
      annotatedSubjectAreaContents.push(action.payload);
      return {
        ...state,
        annotatedSubjectAreaContents: annotatedSubjectAreaContents
      };
    }

    case actionTypes.REMOVE_CUSTOMER_EVENT_ANNOTATED_SUBJECT_AREA_CONTENT: {
      const index = action.payload;
      const annotatedSubjectAreaContents = state.annotatedSubjectAreaContents.slice();
      annotatedSubjectAreaContents.splice(index, 1);
      return {
        ...state,
        annotatedSubjectAreaContents: annotatedSubjectAreaContents
      };
    }

    case actionTypes.SET_CUSTOMER_EVENT_ANNOTATED_SUBJECT_AREA_CONTENT_FIELD_VALUE: {
      return {
        ...state,
        annotatedSubjectAreaContent: {
          ...state.annotatedSubjectAreaContent,
          [action.payload.field]: action.payload.value
        }
      };
    }

    case actionTypes.ADD_CUSTOMER_EVENT_ANNOTATED_SUBJECT_AREA_CONTENT: {
      return {
        ...state,
        annotatedSubjectAreaContent: {
          ...state.annotatedSubjectAreaContent,
          [action.payload.field]: action.payload.value
        }
      };
    }

    case actionTypes.TOGGLE_CUSTOMER_EVENT_DETAILS_ANALYSIS_EXCLUDED_OBJECT_TYPE: {
      const excludedObjectTypes = state.excludedObjectTypes.slice();
      const index = excludedObjectTypes
        .map(t => t.id)
        .indexOf(action.payload.id);

      if (index > -1) {
        excludedObjectTypes.splice(index, 1);
      } else {
        excludedObjectTypes.push(action.payload);
      }

      return {
        ...state,
        excludedObjectTypes: excludedObjectTypes
      };
    }

    case actionTypes.FIND_OBJECT_TYPES_PENDING: {
      return {
        ...state,
        objectTypes: initialState.objectTypes,
        objectTypesLoading: true
      };
    }

    case actionTypes.FIND_OBJECT_TYPES_FULFILLED: {
      return {
        ...state,
        objectTypes: action.payload.data.result,
        objectTypesLoading: false
      };
    }

    case actionTypes.CUSTOMER_EVENT_DETAILS_DEVICE_MONITOR_LIST_PENDING: {
      return {
        ...state,
        deviceMonitoringReportEntries:
          initialState.deviceMonitoringReportEntries,
        deviceMonitoringReportEntriesLoading: true
      };
    }

    case actionTypes.CUSTOMER_EVENT_DETAILS_DEVICE_MONITOR_LIST_FULFILLED: {
      return {
        ...state,
        deviceMonitoringReportEntries: action.payload.data.result,
        deviceMonitoringReportEntriesLoading: false
      };
    }

    case actionTypes.VALIDATE_EVENTS_STATUS_SUMMARY_FULFILLED:
    case actionTypes.MARK_NO_OBJECT_DETECTED_VERIFIED_FULFILLED: {
      const summary = action.payload.data.result;

      return {
        ...state,
        customerEvent: {
          ...state.customerEvent,
          verifiedEventCount: summary.verifiedEvents,
          suspectEventCount: summary.suspectEvents,
          pendingEventCount: summary.pendingEvents,
          testingEventCount: summary.testingEvents
        }
      };
    }

    case actionTypes.FIND_CUSTOMER_EVENT_CELL_EVENT_TIMES_PENDING: {
      return {
        ...state,
        cellEventTimes: initialState.cellEventTimes,
        cellEventTimesLoading: true
      };
    }

    case actionTypes.FIND_CUSTOMER_EVENT_CELL_EVENT_TIMES_FULFILLED: {
      return {
        ...state,
        cellEventTimes: action.payload.data.result,
        cellEventTimesLoading: false
      };
    }

    case actionTypes.CUSTOMER_EVENTS_EVENT_PAGINATED_LIST_PENDING: {
      return {
        ...state,
        events: {
          ...state.events,
          loading: true
        }
      };
    }

    case actionTypes.CUSTOMER_EVENTS_EVENT_PAGINATED_LIST_FULFILLED: {
      return {
        ...state,
        events: {
          ...action.payload.data.result,
          pageSize: state.events.pageSize,
          filter: {
            ...state.events.filter,
            ...action.payload.data.result.filter
          },

          loading: false
        }
      };
    }

    case actionTypes.FIND_CUSTOMER_EVENT_ANALYSIS_OVERVIEW_PENDING: {
      return {
        ...state,
        testingAnalysisOverview: {
          ...initialState.testingAnalysisOverview,
          loading: true
        }
      };
    }

    case actionTypes.FIND_CUSTOMER_EVENT_ANALYSIS_OVERVIEW_FULFILLED: {
      return {
        ...state,
        testingAnalysisOverview: {
          ...action.payload.data.result,
          loading: false
        }
      };
    }

    case actionTypes.SET_CUSTOMER_EVENT_DETAILS_EVENT_LIST_DETAILS_EVENT: {
      return {
        ...state,
        detailsEvent: action.payload
      };
    }

    case actionTypes.FIND_CUSTOMER_EVENT_DETAILS_EVENT_LIST_DETAILS_EVENT_S3_FILES_PENDING: {
      return {
        ...state,
        detailsEventS3Files: initialState.detailsEventS3Files
      };
    }

    case actionTypes.FIND_CUSTOMER_EVENT_DETAILS_EVENT_LIST_DETAILS_EVENT_S3_FILES_FULFILLED: {
      return {
        ...state,
        detailsEventS3Files: action.payload.data.result
      };
    }

    case actionTypes.SAVE_CUSTOMER_EVENT_ANNOTATED_SUBJECT_AREA_CONTENTS_PENDING: {
      return {
        ...state,
        annotatedSubjectAreaContentProcessing: true
      };
    }

    case actionTypes.SAVE_CUSTOMER_EVENT_ANNOTATED_SUBJECT_AREA_CONTENTS_FULFILLED: {
      return {
        ...state,
        customerEvent: action.payload.data.result,
        annotatedSubjectAreaContentProcessing: false
      };
    }

    case actionTypes.SET_CUSTOMER_EVENT_DETAILS_EVENT_LIST_COMMENTS_EVENT: {
      return {
        ...state,
        commentsEvent: {
          ...action.payload,
          comments: !action.payload.comments ? [] : action.payload.comments
        }
      };
    }

    case actionTypes.ADD_COMMENT_FULFILLED:
    case actionTypes.SAVE_COMMENT_FULFILLED:
    case actionTypes.DELETE_COMMENT_FULFILLED: {
      const commentEvent = action.payload.data.result;
      const index = state.events.items.map(e => e.id).indexOf(commentEvent.id);
      const events = state.events.items.slice();

      if (index > -1) {
        const event = {
          ...events[index]
        };
        event.comments = !action.payload.data.result.comments
          ? []
          : action.payload.data.result.comments;
        events[index] = event;
      }

      return {
        ...state,
        events: {
          ...state.events,
          items: events
        },

        commentsEvent: {
          ...state.commentsEvent,
          comments: !action.payload.data.result.comments
            ? []
            : action.payload.data.result.comments
        },
        detailsEvent: {
          ...state.detailsEvent,
          comments: !action.payload.data.result.comments
            ? []
            : action.payload.data.result.comments
        }
      };
    }

    case actionTypes.UPDATE_EVENT_VALIDATION_STATUS_FULFILLED:
    case actionTypes.UPDATE_EVENT_ANNOTATED_PARTICIPANT_GENDER_FULFILLED:
    case actionTypes.TOGGLE_EVENT_SPECIAL_INTEREST_FULFILLED:
    case actionTypes.SAVE_EVENT_ANNOTATED_SUBJECT_AREA_CONTENT_FULFILLED:
    case actionTypes.DELETE_EVENT_ANNOTATED_SUBJECT_AREA_CONTENT_FULFILLED:
    case actionTypes.UPDATE_EVENT_ANNOTATED_PARTICIPANT_CLOTHING_TYPE_FULFILLED: {
      const event = action.payload.data.result;
      const index = state.events.items.map(e => e.id).indexOf(event.id);
      const events = state.events.items.slice();

      if (index > -1) {
        events[index] = event;
      }

      return {
        ...state,
        events: events,
        events: {
          ...state.events,
          items: events
        }
      };
    }

    case actionTypes.CLEAR_FILTER_VALUES: {
      let result;
      if (action.payload.pageName === EVENT_LIST_PAGE) {
        result = {
          ...state,
          events: {
            ...state.events,
            filter: {
              ...initialState.events.filter,
              columnSorts: state.events.filter.columnSorts.slice(),
              search: state.events.filter.search
            }
          }
        };
      } else {
        result = {
          ...state
        };
      }

      return result;
    }

    case actionTypes.GET_FILTER_VALUES_FULFILLED: {
      let result;
      if (
        action.payload.data.result &&
        action.payload.data.result.page === EVENT_LIST_PAGE
      ) {
        result = {
          ...state,
          events: {
            ...state.events,
            filter: {
              ...initialState.events.filter,
              ...action.payload.data.result.filter
            }
          }
        };
      } else {
        result = {
          ...state
        };
      }

      return result;
    }

    case actionTypes.SET_FILTER_VALUE: {
      let result;
      if (action.payload.pageName === EVENT_LIST_PAGE) {
        result = {
          ...state,
          events: {
            ...state.events,
            filter: {
              ...state.events.filter,
              ...action.payload.value
            }
          }
        };
      } else {
        result = {
          ...state
        };
      }

      return result;
    }

    case actionTypes.SET_PAGE_SIZE: {
      let result;
      if (action.payload.pageName === EVENT_LIST_PAGE) {
        result = {
          ...state,
          events: {
            ...state.events,
            pageSize: action.payload.pageSize
          }
        };
      } else {
        result = {
          ...state
        };
      }

      return result;
    }

    case actionTypes.APPLY_FILTER_COLUMN_SORT_FULFILLED: {
      return {
        ...state,
        events: {
          ...state.events,
          filter: {
            ...state.events.filter,
            ...action.payload.value
          }
        }
      };
    }

    case actionTypes.SET_CUSTOMER_EVENT_DETAILS_ANALYSIS_MODEL_CONFIGURATION: {
      return {
        ...state,
        modelConfiguration: action.payload,
        modelSet: null
      };
    }

    case actionTypes.SET_CUSTOMER_EVENT_DETAILS_ANALYSIS_MODEL_SET: {
      return {
        ...state,
        modelConfiguration: null,
        modelSet: action.payload
      };
    }

    case actionTypes.SET_CUSTOMER_EVENT_DETAILS_TORSO_SENSITIVITY: {
      const { torsoThreshold } = action.payload;

      return {
        ...state,
        torsoThreshold: torsoThreshold
      };
    }

    case actionTypes.FIND_CUSTOMER_EVENT_SESSION_GOUPS_PENDING: {
      return {
        ...state,
        sessionGroups: initialState.sessionGroups,
        sessionGroupsLoading: true
      };
    }

    case actionTypes.FIND_CUSTOMER_EVENT_CELL_ANALYSIS_PENDING: {
      return {
        ...state,
        cellAnalysis: initialState.cellAnalysis,
        cellAnalysisLoading: true
      };
    }

    case actionTypes.FIND_CUSTOMER_EVENT_CELL_ANALYSIS_FULFILLED: {
      return {
        ...state,
        cellAnalysis: action.payload.data.result,
        cellAnalysisLoading: false
      };
    }

    case actionTypes.FIND_CUSTOMER_EVENT_CELL_MONITORINGS_PENDING: {
      return {
        ...state,
        cellMonitorings: initialState.cellMonitorings,
        cellMonitoringsLoading: true
      };
    }

    case actionTypes.FIND_CUSTOMER_EVENT_CELL_MONITORINGS_FULFILLED: {
      return {
        ...state,
        cellMonitorings: action.payload.data.result,
        cellMonitoringsLoading: false
      };
    }

    case actionTypes.CELL_MONITORING_EVENT: {
      const monitor = action.payload.result;

      let result;
      if (state.customerEvent.id === monitor.customerEventId) {
        const monitorings = state.cellMonitorings.slice();
        const index = monitorings.map(m => m.cellId).indexOf(monitor.cellId);

        if (index > -1) {
          monitorings[index] = monitor;
        } else {
          monitorings.push(monitor);
        }

        result = {
          ...state,
          cellMonitorings: monitorings,
          cellMonitoringsLoading: false
        };
      } else {
        result = state;
      }

      return result;
    }

    case actionTypes.FIND_CUSTOMER_EVENT_SESSION_GOUPS_FULFILLED: {
      return {
        ...state,
        sessionGroupsLoading: false,
        sessionGroups: action.payload.data.result
      };
    }

    case actionTypes.SET_CUSTOMER_EVENT_DETAILS_SUB_MODEL: {
      return {
        ...state,
        subModel: action.payload
      };
    }

    case actionTypes.SET_CUSTOMER_EVENT_DETAILS_ANALYSIS_CELL: {
      return {
        ...state,
        analysisCell: action.payload
      };
    }

    default: {
      return state;
    }
  }
};

export default customerEventDetailsReducer;
