import { SubjectAreaContent } from 'components';
import ws from '../utils/ws';

/* Actions */

export const customerEventPaginatedList = (pageNumber, pageSize) => dispatch =>
  dispatch({
    type: CUSTOMER_EVENTS_PAGINATED_LIST,
    payload: ws.get(`/customer_events/page/${pageNumber}/rows/${pageSize}`)
  });

export const customerEventCalendarList = () => dispatch =>
  dispatch({
    type: CUSTOMER_EVENT_CALENDAR_LIST,
    payload: ws.get(`/customer_events/calendar_list/`)
  });

export const customerEventMonitorList = reportDate => dispatch =>
  dispatch({
    type: CUSTOMER_EVENT_MONITOR_LIST,
    payload: ws.get(`/walks/monitor/events/?reportTime=${reportDate}`)
  });

export const customerEventDeviceMonitorList = customerEventId => dispatch =>
  dispatch({
    type: CUSTOMER_EVENT_DEVICE_MONITOR_LIST,
    payload: ws.get(
      `/walks/monitor/devices/?customerEventId=${customerEventId}`
    )
  });

export const customerEventDetailsDeviceMonitorList = customerEventId => dispatch =>
  dispatch({
    type: CUSTOMER_EVENT_DETAILS_DEVICE_MONITOR_LIST,
    payload: ws.get(`/customer_events/${customerEventId}/devices/`)
  });

export const clearCustomerEventDeviceMonitorList = () => dispatch =>
  dispatch({
    type: CLEAR_CUSTOMER_EVENT_DEVICE_MONITOR_LIST
  });

export const setCustomerEventMonitorListMinimumWalkCount = walkCount => dispatch =>
  dispatch({
    type: SET_CUSTOMER_EVENT_MONITOR_LIST_MINIMUM_WALK_COUNT,
    payload: walkCount
  });

export const customerEventEventPaginatedList = (
  customerEventId,
  pageNumber,
  pageSize,
  modelConfiguration,
  modelSet,
  torsoThreshold,
  cell
) => dispatch =>
  dispatch({
    type: CUSTOMER_EVENTS_EVENT_PAGINATED_LIST,
    payload: ws.get(
      `/customer_events/${customerEventId}/events/page/${pageNumber}/rows/${pageSize}` +
        '?modelConfigurationId=' +
        (modelConfiguration && modelConfiguration.id
          ? modelConfiguration.id
          : '') +
        '&modelSetId=' +
        (modelSet && modelSet.id ? modelSet.id : '') +
        '&torsoThreshold=' +
        torsoThreshold +
        (cell ? '&cell=' + cell.id : '')
    )
  });

export const customerEventValueUpdate = values => dispatch =>
  dispatch({
    type: CREATE_CUSTOMER_EVENT_VALUE_UPDATE,
    payload: values
  });

export const resetCustomerEventAnnotatedSubjectAreaContent = () => dispatch =>
  dispatch({
    type: RESET_CUSTOMER_EVENT_ANNOTATED_SUBJECT_AREA_CONTENT
  });

export const resetCustomerEventAnnotatedSubjectAreaContents = () => dispatch =>
  dispatch({
    type: RESET_CUSTOMER_EVENT_ANNOTATED_SUBJECT_AREA_CONTENTS
  });

export const addCustomerEventAnnotatedSubjectAreaContent = subjectAreaContent => dispatch =>
  dispatch({
    type: ADD_CUSTOMER_EVENT_ANNOTATED_SUBJECT_AREA_CONTENT,
    payload: subjectAreaContent
  });

export const removeCustomerEventAnnotatedSubjectAreaContent = index => dispatch =>
  dispatch({
    type: REMOVE_CUSTOMER_EVENT_ANNOTATED_SUBJECT_AREA_CONTENT,
    payload: index
  });

export const saveCustomerEventAnnotatedSubjectAreaContents = (
  customerEventId,
  subjectAreaContents
) => dispatch =>
  dispatch({
    type: SAVE_CUSTOMER_EVENT_ANNOTATED_SUBJECT_AREA_CONTENTS,
    payload: ws.post(
      `/customer_events/${customerEventId}/annotate_walks`,
      subjectAreaContents
    )
  });

export const setCustomerEventAnnotatedSubjectAreaContentFieldValue = (
  field,
  value
) => dispatch =>
  dispatch({
    type: SET_CUSTOMER_EVENT_ANNOTATED_SUBJECT_AREA_CONTENT_FIELD_VALUE,
    payload: { field, value }
  });

export const customerEventValueReset = () => dispatch =>
  dispatch({
    type: CREATE_CUSTOMER_EVENT_VALUE_RESET
  });

export const saveCustomerEvent = (
  customerEvent,
  excludedCellComponentIds,
  cellComponentVersions
) => dispatch =>
  dispatch({
    type: SAVE_CUSTOMER_EVENT,
    payload: ws.post('/customer_events/', {
      customerEvent,
      excludedCellComponentIds,
      cellComponentVersions
    })
  });

export const toggleValidateCustomerEventEventSpecialInterest = eventId => dispatch =>
  dispatch({
    type: TOGGLE_VALIDATE_CUSTOMER_EVENT_SPECIAL_INTEREST,
    payload: ws.get(`/events/${eventId}/toggle_special_interest`)
  });

export const deleteCustomerEvent = customerEvent => dispatch =>
  dispatch({
    type: DELETE_CUSTOMER_EVENT,
    payload: ws.post(`/customer_events/${customerEvent.id}/delete`)
  });

export const editCustomerEvent = id => dispatch =>
  dispatch({
    type: EDIT_CUSTOMER_EVENT,
    payload: ws.get(`/customer_events/${id}`)
  });

export const findCustomerEvents = () => dispatch =>
  dispatch({
    type: FIND_CUSTOMER_EVENTS,
    payload: ws.get('/customer_events/')
  });

export const findGroupsForCustomerEvent = id => dispatch =>
  dispatch({
    type: FIND_CUSTOMER_EVENT_SESSION_GOUPS,
    payload: ws.get(`/customer_events/${id}/groups/`)
  });

export const findCustomerEventWeatherData = id => dispatch =>
  dispatch({
    type: FIND_CUSTOMER_EVENT_WEATHER_DATA,
    payload: ws.get(`/customer_events/${id}/weather_data/`)
  });

export const findCustomerEvent = id => dispatch =>
  dispatch({
    type: FIND_CUSTOMER_EVENT,
    payload: ws.get(`/customer_events/${id}`)
  });

export const findCustomerEventCellEventTimes = id => dispatch =>
  dispatch({
    type: FIND_CUSTOMER_EVENT_CELL_EVENT_TIMES,
    payload: ws.get(`/customer_events/${id}/events/cell_event_times/`)
  });

export const findCustomerEventCellWalkCounts = id => dispatch =>
  dispatch({
    type: FIND_CUSTOMER_EVENT_CELL_WALK_COUNTS,
    payload: ws.get(`/customer_events/${id}/cells/walk_counts/`)
  });

export const clearCustomerEventDeleteMessage = () => dispatch =>
  dispatch({
    type: CLEAR_CUSTOMER_EVENT_DELETE_MESSAGE
  });

export const resetValidateCustomerEvent = () => dispatch =>
  dispatch({
    type: RESET_VALIDATE_CUSTOMER_EVENT
  });

export const setCustomerEventDetailsEventListDetailsEvent = event => dispatch =>
  dispatch({
    type: SET_CUSTOMER_EVENT_DETAILS_EVENT_LIST_DETAILS_EVENT,
    payload: event
  });

export const findCustomerEventDetailsEventListDetailsEventS3Files = eventId => dispatch =>
  dispatch({
    type: FIND_CUSTOMER_EVENT_DETAILS_EVENT_LIST_DETAILS_EVENT_S3_FILES,
    payload: ws.get('/events/' + eventId + '/s3_files/')
  });

export const setCustomerEventDetailsEventListCommentsEvent = event => dispatch =>
  dispatch({
    type: SET_CUSTOMER_EVENT_DETAILS_EVENT_LIST_COMMENTS_EVENT,
    payload: event
  });

export const toggleCustomerEventDetailsAnalysisExcludedObjectType = objectType => dispatch =>
  dispatch({
    type: TOGGLE_CUSTOMER_EVENT_DETAILS_ANALYSIS_EXCLUDED_OBJECT_TYPE,
    payload: objectType
  });

export const findCustomerEventAnalysisOverview = (
  customerEventId,
  modelConfiguration,
  modelSet,
  torsoThreshold,
  cell
) => dispatch =>
  dispatch({
    type: FIND_CUSTOMER_EVENT_ANALYSIS_OVERVIEW,
    payload: ws.get(
      `/customer_events/${customerEventId}/analysis/overview${'?modelConfigurationId=' +
        (modelConfiguration && modelConfiguration.id
          ? modelConfiguration.id
          : '') +
        '&modelSetId=' +
        (modelSet && modelSet.id ? modelSet.id : '') +
        '&torsoThreshold=' +
        torsoThreshold +
        (cell ? '&cell=' + cell.id : '')}`
    )
  });

export const findCustomerEventCellAnalysis = (
  customerEventId,
  modelConfiguration,
  torsoThreshold
) => dispatch =>
  dispatch({
    type: FIND_CUSTOMER_EVENT_CELL_ANALYSIS,
    payload: ws.get(
      `/customer_events/${customerEventId}/analysis/cells${'?1=1' +
        (modelConfiguration
          ? '&modelConfigurationId=' + modelConfiguration.id
          : '') +
        // + '&torsoThreshold=' + torsoThreshold
        '&torsoThreshold=50'}`
    )
  });

export const findCustomerEventCellMonitorings = customerEventId => dispatch =>
  dispatch({
    type: FIND_CUSTOMER_EVENT_CELL_MONITORINGS,
    payload: ws.get(`/customer_events/${customerEventId}/monitoring/`)
  });

export const findCustomerEventCellHealthMeasures = customerEventId => dispatch =>
  dispatch({
    type: FIND_CUSTOMER_EVENT_CELL_HEALTH_MEASURES,
    payload: ws.get(`/customer_events/${customerEventId}/cell_health_measures/`)
  });

export const findCustomerEventCellHealthTickets = customerEventId => dispatch =>
  dispatch({
    type: FIND_CUSTOMER_EVENT_CELL_HEALTH_TICKETS,
    payload: ws.get(`/customer_events/${customerEventId}/cell_tickets/`)
  });

export const findCustomerEventCellThresholdBreaches = customerEventId => dispatch =>
  dispatch({
    type: FIND_CUSTOMER_EVENT_CELL_THRESHOLD_BREACHES,
    payload: ws.get(
      `/customer_events/${customerEventId}/cell_threshold_breaches/`
    )
  });

export const findCustomerEventCellThresholdBreachSummaries = customerEventId => dispatch =>
  dispatch({
    type: FIND_CUSTOMER_EVENT_CELL_THRESHOLD_BREACH_SUMMARIES,
    payload: ws.get(
      `/customer_events/${customerEventId}/cell_threshold_breach_summaries/`
    )
  });

export const setCustomerEventDetailsModelConfiguration = modelConfiguration => dispatch =>
  dispatch({
    type: SET_CUSTOMER_EVENT_DETAILS_ANALYSIS_MODEL_CONFIGURATION,
    payload: modelConfiguration
  });

export const setCustomerEventDetailsModelSet = modelSet => dispatch =>
  dispatch({
    type: SET_CUSTOMER_EVENT_DETAILS_ANALYSIS_MODEL_SET,
    payload: modelSet
  });

export const setCustomerEventDetailsTorsoThreshold = torsoThreshold => dispatch =>
  dispatch({
    type: SET_CUSTOMER_EVENT_DETAILS_TORSO_SENSITIVITY,
    payload: {
      torsoThreshold
    }
  });

export const selectCustomerEventListEvent = customerEvent => dispatch =>
  dispatch({
    type: SELECT_CUSTOMER_EVENT_LIST_EVENT,
    payload: customerEvent.id
  });

export const selectAllCustomerEventListEvents = () => dispatch =>
  dispatch({
    type: SELECT_ALL_CUSTOMER_EVENT_LIST_EVENTS,
    payload: ws.get(`/customer_events/ids/`)
  });

export const deselectAllCustomerEventListEvents = () => dispatch =>
  dispatch({
    type: DESELECT_ALL_CUSTOMER_EVENT_LIST_EVENTS
  });

export const clearCustomerEventListSelectedEvents = () => dispatch =>
  dispatch({
    type: CLEAR_CUSTOMER_EVENT_LIST_SELECTED_EVENTS
  });

export const createCustomerEventSummary = () => dispatch => {
  dispatch({
    type: CREATE_CUSTOMER_EVENT_SUMMARY,
    payload: ws.post(`/customer_events/summary/create`)
  });
};

export const findLatestCustomerEventSummary = () => dispatch => {
  dispatch({
    type: FIND_LATEST_CUSTOMER_EVENT_SUMMARY,
    payload: ws.get(`/customer_events/summary/latest`)
  });
};

export const setCustomerEventDetailsSubModel = subModel => dispatch =>
  dispatch({
    type: SET_CUSTOMER_EVENT_DETAILS_SUB_MODEL,
    payload: subModel
  });

export const setCustomerEventDetailsAnalysisCell = cell => dispatch =>
  dispatch({
    type: SET_CUSTOMER_EVENT_DETAILS_ANALYSIS_CELL,
    payload: cell
  });

/* Constants */

export const CUSTOMER_EVENTS_PAGINATED_LIST = 'CUSTOMER_EVENTS_PAGINATED_LIST';
export const CUSTOMER_EVENTS_PAGINATED_LIST_PENDING =
  'CUSTOMER_EVENTS_PAGINATED_LIST_PENDING';
export const CUSTOMER_EVENTS_PAGINATED_LIST_FULFILLED =
  'CUSTOMER_EVENTS_PAGINATED_LIST_FULFILLED';

export const CUSTOMER_EVENT_CALENDAR_LIST = 'CUSTOMER_EVENT_CALENDAR_LIST';
export const CUSTOMER_EVENT_CALENDAR_LIST_PENDING =
  'CUSTOMER_EVENT_CALENDAR_LIST_PENDING';
export const CUSTOMER_EVENT_CALENDAR_LIST_FULFILLED =
  'CUSTOMER_EVENT_CALENDAR_LIST_FULFILLED';

export const CUSTOMER_EVENT_MONITOR_LIST = 'CUSTOMER_EVENT_MONITOR_LIST';
export const CUSTOMER_EVENT_MONITOR_LIST_PENDING =
  'CUSTOMER_EVENT_MONITOR_LIST_PENDING';
export const CUSTOMER_EVENT_MONITOR_LIST_FULFILLED =
  'CUSTOMER_EVENT_MONITOR_LIST_FULFILLED';

export const CUSTOMER_EVENT_DEVICE_MONITOR_LIST =
  'CUSTOMER_EVENT_DEVICE_MONITOR_LIST';
export const CUSTOMER_EVENT_DEVICE_MONITOR_LIST_PENDING =
  'CUSTOMER_EVENT_DEVICE_MONITOR_LIST_PENDING';
export const CUSTOMER_EVENT_DEVICE_MONITOR_LIST_FULFILLED =
  'CUSTOMER_EVENT_DEVICE_MONITOR_LIST_FULFILLED';

export const CUSTOMER_EVENT_DETAILS_DEVICE_MONITOR_LIST =
  'CUSTOMER_EVENT_DETAILS_DEVICE_MONITOR_LIST';
export const CUSTOMER_EVENT_DETAILS_DEVICE_MONITOR_LIST_PENDING =
  'CUSTOMER_EVENT_DETAILS_DEVICE_MONITOR_LIST_PENDING';
export const CUSTOMER_EVENT_DETAILS_DEVICE_MONITOR_LIST_FULFILLED =
  'CUSTOMER_EVENT_DETAILS_DEVICE_MONITOR_LIST_FULFILLED';

export const SET_CUSTOMER_EVENT_MONITOR_LIST_MINIMUM_WALK_COUNT =
  'SET_CUSTOMER_EVENT_MONITOR_LIST_MINIMUM_WALK_COUNT';

export const CUSTOMER_EVENTS_EVENT_PAGINATED_LIST =
  'CUSTOMER_EVENTS_EVENT_PAGINATED_LIST';
export const CUSTOMER_EVENTS_EVENT_PAGINATED_LIST_PENDING =
  'CUSTOMER_EVENTS_EVENT_PAGINATED_LIST_PENDING';
export const CUSTOMER_EVENTS_EVENT_PAGINATED_LIST_FULFILLED =
  'CUSTOMER_EVENTS_EVENT_PAGINATED_LIST_FULFILLED';

export const RESET_CUSTOMER_EVENT_ANNOTATED_SUBJECT_AREA_CONTENT =
  'RESET_CUSTOMER_EVENT_ANNOTATED_SUBJECT_AREA_CONTENT';

export const RESET_CUSTOMER_EVENT_ANNOTATED_SUBJECT_AREA_CONTENTS =
  'RESET_CUSTOMER_EVENT_ANNOTATED_SUBJECT_AREA_CONTENTS';

export const ADD_CUSTOMER_EVENT_ANNOTATED_SUBJECT_AREA_CONTENT =
  'ADD_CUSTOMER_EVENT_ANNOTATED_SUBJECT_AREA_CONTENT';

export const REMOVE_CUSTOMER_EVENT_ANNOTATED_SUBJECT_AREA_CONTENT =
  'REMOVE_CUSTOMER_EVENT_ANNOTATED_SUBJECT_AREA_CONTENT';

export const SAVE_CUSTOMER_EVENT_ANNOTATED_SUBJECT_AREA_CONTENTS =
  'SAVE_CUSTOMER_EVENT_ANNOTATED_SUBJECT_AREA_CONTENTS';
export const SAVE_CUSTOMER_EVENT_ANNOTATED_SUBJECT_AREA_CONTENTS_PENDING =
  'SAVE_CUSTOMER_EVENT_ANNOTATED_SUBJECT_AREA_CONTENTS_PENDING';
export const SAVE_CUSTOMER_EVENT_ANNOTATED_SUBJECT_AREA_CONTENTS_FULFILLED =
  'SAVE_CUSTOMER_EVENT_ANNOTATED_SUBJECT_AREA_CONTENTS_FULFILLED';

export const SET_CUSTOMER_EVENT_ANNOTATED_SUBJECT_AREA_CONTENT_FIELD_VALUE =
  'SET_CUSTOMER_EVENT_ANNOTATED_SUBJECT_AREA_CONTENT_FIELD_VALUE';

export const CREATE_CUSTOMER_EVENT_VALUE_UPDATE =
  'CREATE_CUSTOMER_EVENTS_VALUE_UPDATE';
export const CREATE_CUSTOMER_EVENT_VALUE_RESET =
  'CREATE_CUSTOMER_EVENT_VALUE_RESET';

export const SET_CUSTOMER_EVENT_COMPONENT_EXCLUDED =
  'SET_CUSTOMER_EVENT_COMPONENT_EXCLUDED';

export const SAVE_CUSTOMER_EVENT = 'SAVE_CUSTOMER_EVENT';
export const SAVE_CUSTOMER_EVENT_PENDING = 'SAVE_CUSTOMER_EVENT_PENDING';
export const SAVE_CUSTOMER_EVENT_FULFILLED = 'SAVE_CUSTOMER_EVENT_FULFILLED';

export const FIND_CUSTOMER_EVENT_CELL_EVENT_TIMES =
  'FIND_CUSTOMER_EVENT_CELL_EVENT_TIMES';
export const FIND_CUSTOMER_EVENT_CELL_EVENT_TIMES_PENDING =
  'FIND_CUSTOMER_EVENT_CELL_EVENT_TIMES_PENDING';
export const FIND_CUSTOMER_EVENT_CELL_EVENT_TIMES_FULFILLED =
  'FIND_CUSTOMER_EVENT_CELL_EVENT_TIMES_FULFILLED';

export const FIND_CUSTOMER_EVENT_CELL_WALK_COUNTS =
  'FIND_CUSTOMER_EVENT_CELL_WALK_COUNTS';
export const FIND_CUSTOMER_EVENT_CELL_WALK_COUNTS_PENDING =
  'FIND_CUSTOMER_EVENT_CELL_WALK_COUNTS_PENDING';
export const FIND_CUSTOMER_EVENT_CELL_WALK_COUNTS_FULFILLED =
  'FIND_CUSTOMER_EVENT_CELL_WALK_COUNTS_FULFILLED';

export const FIND_CUSTOMER_EVENT_SESSION_GOUPS =
  'FIND_CUSTOMER_EVENT_SESSION_GOUPS';
export const FIND_CUSTOMER_EVENT_SESSION_GOUPS_PENDING =
  'FIND_CUSTOMER_EVENT_SESSION_GOUPS_PENDING';
export const FIND_CUSTOMER_EVENT_SESSION_GOUPS_FULFILLED =
  'FIND_CUSTOMER_EVENT_SESSION_GOUPS_FULFILLED';

export const FIND_CUSTOMER_EVENT_WEATHER_DATA =
  'FIND_CUSTOMER_EVENT_WEATHER_DATA';
export const FIND_CUSTOMER_EVENT_WEATHER_DATA_PENDING =
  'FIND_CUSTOMER_EVENT_WEATHER_DATA_PENDING';
export const FIND_CUSTOMER_EVENT_WEATHER_DATA_FULFILLED =
  'FIND_CUSTOMER_EVENT_WEATHER_DATA_FULFILLED';

export const DELETE_CUSTOMER_EVENT = 'DELETE_CUSTOMER_EVENT';
export const DELETE_CUSTOMER_EVENT_PENDING = 'DELETE_CUSTOMER_EVENT_PENDING';
export const DELETE_CUSTOMER_EVENT_FULFILLED =
  'DELETE_CUSTOMER_EVENT_FULFILLED';

export const EDIT_CUSTOMER_EVENT = 'EDIT_CUSTOMER_EVENT';
export const EDIT_CUSTOMER_EVENT_FULFILLED = 'EDIT_CUSTOMER_EVENT_FULFILLED';

export const FIND_CUSTOMER_EVENT_COMPONENT_VERSIONS =
  'FIND_CUSTOMER_EVENT_COMPONENT_VERSIONS';
export const FIND_CUSTOMER_EVENT_COMPONENT_VERSIONS_PENDING =
  'FIND_CUSTOMER_EVENT_COMPONENT_VERSIONS_PENDING';
export const FIND_CUSTOMER_EVENT_COMPONENT_VERSIONS_FULFILLED =
  'FIND_CUSTOMER_EVENT_COMPONENT_VERSIONS_FULFILLED';

export const FIND_CUSTOMER_EVENTS = 'FIND_CUSTOMER_EVENTS';
export const FIND_CUSTOMER_EVENTS_PENDING = 'FIND_CUSTOMER_EVENTS_PENDING';
export const FIND_CUSTOMER_EVENTS_FULFILLED = 'FIND_CUSTOMER_EVENTS_FULFILLED';

export const FIND_CUSTOMER_EVENT = 'FIND_CUSTOMER_EVENT';
export const FIND_CUSTOMER_EVENT_PENDING = 'FIND_CUSTOMER_EVENT_PENDING';
export const FIND_CUSTOMER_EVENT_FULFILLED = 'FIND_CUSTOMER_EVENT_FULFILLED';

export const CLEAR_CUSTOMER_EVENT_DELETE_MESSAGE =
  'CLEAR_CUSTOMER_EVENT_DELETE_MESSAGE';

export const RESET_VALIDATE_CUSTOMER_EVENT = 'RESET_VALIDATE_CUSTOMER_EVENT';

export const SET_CUSTOMER_EVENT_DETAILS_EVENT_LIST_DETAILS_EVENT =
  'SET_CUSTOMER_EVENT_DETAILS_EVENT_LIST_DETAILS_EVENT';

export const FIND_CUSTOMER_EVENT_DETAILS_EVENT_LIST_DETAILS_EVENT_S3_FILES =
  'FIND_CUSTOMER_EVENT_DETAILS_EVENT_LIST_DETAILS_EVENT_S3_FILES';
export const FIND_CUSTOMER_EVENT_DETAILS_EVENT_LIST_DETAILS_EVENT_S3_FILES_PENDING =
  'FIND_CUSTOMER_EVENT_DETAILS_EVENT_LIST_DETAILS_EVENT_S3_FILES_PENDING';
export const FIND_CUSTOMER_EVENT_DETAILS_EVENT_LIST_DETAILS_EVENT_S3_FILES_FULFILLED =
  'FIND_CUSTOMER_EVENT_DETAILS_EVENT_LIST_DETAILS_EVENT_S3_FILES_FULFILLED';

export const SET_CUSTOMER_EVENT_DETAILS_EVENT_LIST_COMMENTS_EVENT =
  'SET_CUSTOMER_EVENT_DETAILS_EVENT_LIST_COMMENTS_EVENT';

export const TOGGLE_CUSTOMER_EVENT_DETAILS_ANALYSIS_EXCLUDED_OBJECT_TYPE =
  'TOGGLE_CUSTOMER_EVENT_DETAILS_ANALYSIS_EXCLUDED_OBJECT_TYPE';

export const CLEAR_CUSTOMER_EVENT_DEVICE_MONITOR_LIST =
  'CLEAR_CUSTOMER_EVENT_DEVICE_MONITOR_LIST';

export const FIND_CUSTOMER_EVENT_ANALYSIS_OVERVIEW =
  'FIND_CUSTOMER_EVENT_ANALYSIS_OVERVIEW';
export const FIND_CUSTOMER_EVENT_ANALYSIS_OVERVIEW_PENDING =
  'FIND_CUSTOMER_EVENT_ANALYSIS_OVERVIEW_PENDING';
export const FIND_CUSTOMER_EVENT_ANALYSIS_OVERVIEW_FULFILLED =
  'FIND_CUSTOMER_EVENT_ANALYSIS_OVERVIEW_FULFILLED';

export const FIND_CUSTOMER_EVENT_CELL_ANALYSIS =
  'FIND_CUSTOMER_EVENT_CELL_ANALYSIS';
export const FIND_CUSTOMER_EVENT_CELL_ANALYSIS_PENDING =
  'FIND_CUSTOMER_EVENT_CELL_ANALYSIS_PENDING';
export const FIND_CUSTOMER_EVENT_CELL_ANALYSIS_FULFILLED =
  'FIND_CUSTOMER_EVENT_CELL_ANALYSIS_FULFILLED';

export const FIND_CUSTOMER_EVENT_CELL_MONITORINGS =
  'FIND_CUSTOMER_EVENT_CELL_MONITORINGS';
export const FIND_CUSTOMER_EVENT_CELL_MONITORINGS_PENDING =
  'FIND_CUSTOMER_EVENT_CELL_MONITORINGS_PENDING';
export const FIND_CUSTOMER_EVENT_CELL_MONITORINGS_FULFILLED =
  'FIND_CUSTOMER_EVENT_CELL_MONITORINGS_FULFILLED';

export const FIND_CUSTOMER_EVENT_CELL_HEALTH_MEASURES =
  'FIND_CUSTOMER_EVENT_CELL_HEALTH_MEASURES';
export const FIND_CUSTOMER_EVENT_CELL_HEALTH_MEASURES_PENDING =
  'FIND_CUSTOMER_EVENT_CELL_HEALTH_MEASURES_PENDING';
export const FIND_CUSTOMER_EVENT_CELL_HEALTH_MEASURES_FULFILLED =
  'FIND_CUSTOMER_EVENT_CELL_HEALTH_MEASURES_FULFILLED';

export const FIND_CUSTOMER_EVENT_CELL_HEALTH_TICKETS =
  'FIND_CUSTOMER_EVENT_CELL_HEALTH_TICKETS';
export const FIND_CUSTOMER_EVENT_CELL_HEALTH_TICKETS_PENDING =
  'FIND_CUSTOMER_EVENT_CELL_HEALTH_TICKETS_PENDING';
export const FIND_CUSTOMER_EVENT_CELL_HEALTH_TICKETS_FULFILLED =
  'FIND_CUSTOMER_EVENT_CELL_HEALTH_TICKETS_FULFILLED';

export const FIND_CUSTOMER_EVENT_CELL_THRESHOLD_BREACHES =
  'FIND_CUSTOMER_EVENT_CELL_THRESHOLD_BREACHES';
export const FIND_CUSTOMER_EVENT_CELL_THRESHOLD_BREACHES_PENDING =
  'FIND_CUSTOMER_EVENT_CELL_THRESHOLD_BREACHES_PENDING';
export const FIND_CUSTOMER_EVENT_CELL_THRESHOLD_BREACHES_FULFILLED =
  'FIND_CUSTOMER_EVENT_CELL_THRESHOLD_BREACHES_FULFILLED';

export const FIND_CUSTOMER_EVENT_CELL_THRESHOLD_BREACH_SUMMARIES =
  'FIND_CUSTOMER_EVENT_CELL_THRESHOLD_BREACH_SUMMARIES';
export const FIND_CUSTOMER_EVENT_CELL_THRESHOLD_BREACH_SUMMARIES_PENDING =
  'FIND_CUSTOMER_EVENT_CELL_THRESHOLD_BREACH_SUMMARIES_PENDING';
export const FIND_CUSTOMER_EVENT_CELL_THRESHOLD_BREACH_SUMMARIES_FULFILLED =
  'FIND_CUSTOMER_EVENT_CELL_THRESHOLD_BREACH_SUMMARIES_FULFILLED';

export const CELL_MONITORING_EVENT = 'CELL_MONITORING_EVENT';

export const SET_CUSTOMER_EVENT_DETAILS_ANALYSIS_MODEL_CONFIGURATION =
  'SET_CUSTOMER_EVENT_DETAILS_ANALYSIS_MODEL_CONFIGURATION';

export const SET_CUSTOMER_EVENT_DETAILS_ANALYSIS_MODEL_SET =
  'SET_CUSTOMER_EVENT_DETAILS_ANALYSIS_MODEL_SET';

export const SET_CUSTOMER_EVENT_DETAILS_TORSO_SENSITIVITY =
  'SET_CUSTOMER_EVENT_DETAILS_TORSO_SENSITIVITY';

export const SELECT_CUSTOMER_EVENT_LIST_EVENT =
  'SELECT_CUSTOMER_EVENT_LIST_EVENT';
export const DESELECT_ALL_CUSTOMER_EVENT_LIST_EVENTS =
  'DESELECT_ALL_CUSTOMER_EVENT_LIST_EVENTS';
export const CLEAR_CUSTOMER_EVENT_LIST_SELECTED_EVENTS =
  'CLEAR_CUSTOMER_EVENT_LIST_SELECTED_EVENTS';

export const SELECT_ALL_CUSTOMER_EVENT_LIST_EVENTS =
  'SELECT_ALL_CUSTOMER_EVENT_LIST_EVENTS';
export const SELECT_ALL_CUSTOMER_EVENT_LIST_EVENTS_PENDING =
  'SELECT_ALL_CUSTOMER_EVENT_LIST_EVENTS_PENDING';
export const SELECT_ALL_CUSTOMER_EVENT_LIST_EVENTS_FULFILLED =
  'SELECT_ALL_CUSTOMER_EVENT_LIST_EVENTS_FULFILLED';

export const TOGGLE_VALIDATE_CUSTOMER_EVENT_SPECIAL_INTEREST =
  'TOGGLE_VALIDATE_CUSTOMER_EVENT_SPECIAL_INTEREST';
export const TOGGLE_VALIDATE_CUSTOMER_EVENT_SPECIAL_INTEREST_PENDING =
  'TOGGLE_VALIDATE_CUSTOMER_EVENT_SPECIAL_INTEREST_PENDING';
export const TOGGLE_VALIDATE_CUSTOMER_EVENT_SPECIAL_INTEREST_FULFILLED =
  'TOGGLE_VALIDATE_CUSTOMER_EVENT_SPECIAL_INTEREST_FULFILLED';

export const CREATE_CUSTOMER_EVENT_SUMMARY = 'CREATE_CUSTOMER_EVENT_SUMMARY';
export const CREATE_CUSTOMER_EVENT_SUMMARY_PENDING =
  'CREATE_CUSTOMER_EVENT_SUMMARY_PENDING';
export const CREATE_CUSTOMER_EVENT_SUMMARY_FULFILLED =
  'CREATE_CUSTOMER_EVENT_SUMMARY_FULFILLED';

export const FIND_LATEST_CUSTOMER_EVENT_SUMMARY =
  'CREATE_CUSTOMER_EVENT_SUMMARY';
export const FIND_LATEST_CUSTOMER_EVENT_SUMMARY_PENDING =
  'FIND_LATEST_CUSTOMER_EVENT_SUMMARY_PENDING';
export const FIND_LATEST_CUSTOMER_EVENT_SUMMARY_FULFILLED =
  'FIND_LATEST_CUSTOMER_EVENT_SUMMARY_FULFILLED';

export const SET_CUSTOMER_EVENT_DETAILS_AREA_GROUP =
  'SET_CUSTOMER_EVENT_DETAILS_AREA_GROUP';

export const SET_CUSTOMER_EVENT_DETAILS_SUB_MODEL =
  'SET_CUSTOMER_EVENT_DETAILS_SUB_MODEL';

export const SET_CUSTOMER_EVENT_DETAILS_ANALYSIS_CELL =
  'SET_CUSTOMER_EVENT_DETAILS_ANALYSIS_CELL';
