import React from 'react';
import { NumberFormat } from 'components';
import { Link as RouterLink } from 'react-router-dom';
import {
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  colors,
  TableSortLabel
} from '@material-ui/core';
import { faSort } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  root: {},
  modal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    outline: 'none',
    boxShadow: theme.shadows[20],
    width: '90%',
    maxHeight: '100%',
    overflowY: 'auto',
    maxWidth: '100%'
  },
  content: {
    padding: 0
  },
  filters: {
    padding: theme.spacing(2),
    paddingLeft: theme.spacing(3)
  },
  filterButton: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(2)
  },
  container: {
    maxHeight: 592
  },
  sortable: {
    fontSize: 14,
    opacity: 0.6,
    marginLeft: theme.spacing(1)
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));

const WalkMonitoringReportEntryListTable = props => {
  const {
    reportEntries,
    entityType,
    handleExpandDevices,
    columnSort,
    deviceColumnSort,
    setColumnSort,
    setDeviceColumnSort
  } = props;

  const classes = useStyles();

  const ColumnSort = props => {
    const { label, column, entityType } = props;

    const sort = entityType === 'DEVICES' ? deviceColumnSort : columnSort;
    const setSort =
      entityType === 'DEVICES' ? setDeviceColumnSort : setColumnSort;

    return (
      <TableSortLabel
        active={sort?.column == column}
        direction={sort?.direction}
        onClick={() =>
          setSort({
            column,
            direction: sort?.direction === 'asc' ? 'desc' : 'asc'
          })
        }
        hideSortIcon={sort === undefined}>
        {label}{' '}
        {sort?.column != column && (
          <FontAwesomeIcon className={classes.sortable} icon={faSort} />
        )}
      </TableSortLabel>
    );
  };

  return (
    <Table stickyHeader>
      <TableHead>
        <TableRow>
          {entityType === 'DEVICES' ? (
            <>
              <TableCell>
                <ColumnSort
                  label="Device"
                  column="cellName"
                  entityType={entityType}
                />
              </TableCell>
              <TableCell>
                <ColumnSort
                  label="Alias"
                  column="cellCustomerDeviceName"
                  entityType={entityType}
                />
              </TableCell>
            </>
          ) : (
            <>
              <TableCell>
                <ColumnSort
                  label="Venue"
                  column="venueName"
                  entityType={entityType}
                />
              </TableCell>
              <TableCell>
                <ColumnSort
                  label="Devices"
                  column="associatedCellCount"
                  entityType={entityType}
                />
              </TableCell>
            </>
          )}
          <TableCell>
            <ColumnSort
              label="Walks"
              column="walkCount"
              entityType={entityType}
            />
          </TableCell>
          <TableCell>
            <ColumnSort
              label="No Obj Radar Avg Score"
              column="noObjectRadarAverageScore"
              entityType={entityType}
            />
          </TableCell>
          <TableCell>
            <ColumnSort
              label="No Obj Radar Accy"
              column="noObjectRadarAccuracy"
              entityType={entityType}
            />
          </TableCell>
          <TableCell>
            <ColumnSort
              label="No Obj Mag Accy"
              column="noObjectMagnetometerAccuracy"
              entityType={entityType}
            />
          </TableCell>
          <TableCell>
            <ColumnSort
              label="Torso Object Walks"
              column="torsoObjectWalkCount"
              entityType={entityType}
            />
          </TableCell>
          <TableCell>
            <ColumnSort
              label="Radar Obj Accy"
              column="objectAccuracy"
              entityType={entityType}
            />
          </TableCell>
          <TableCell>
            <ColumnSort
              label="Ankle Object Walks"
              column="ankleObjectWalkCount"
              entityType={entityType}
            />
          </TableCell>
          <TableCell>
            <ColumnSort
              label="Mag Obj Accy"
              column="objectAccuracy"
              entityType={entityType}
            />
          </TableCell>
          <TableCell>
            <ColumnSort
              label="Mag Deactivated"
              column="magnetometerDeactivatedCount"
              entityType={entityType}
            />
          </TableCell>
          <TableCell>
            <ColumnSort
              label="Thresholds"
              column="thresholds"
              entityType={entityType}
            />
          </TableCell>
          <TableCell>Models</TableCell>
          <TableCell>
            <ColumnSort
              label="Temp. (F)"
              column="averageWeatherTemperatureFahrenheit"
              entityType={entityType}
            />
          </TableCell>
          <TableCell>
            <ColumnSort
              label="Wind (mph)"
              column="averageWeatherWindSpeedMph"
              entityType={entityType}
            />
          </TableCell>
          <TableCell>
            <ColumnSort
              label="Precip. (in)"
              column="averageWeatherPrecipitationIn"
              entityType={entityType}
            />
          </TableCell>
          <TableCell>
            <ColumnSort
              label="Software"
              column="softwareVersions"
              entityType={entityType}
            />
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {reportEntries.map((entry, i) => (
          <TableRow hover key={i}>
            {entityType === 'DEVICES' ? (
              <>
                <TableCell>
                  <Link
                    target="_blank"
                    color="inherit"
                    component={RouterLink}
                    to={
                      '/deployment/cells/' + entry.cellId + '/customer_events'
                    }
                    variant="h6">
                    {entry.cellName}
                  </Link>
                </TableCell>
                <TableCell>{entry.cellCustomerDeviceName}</TableCell>
              </>
            ) : (
              <>
                <TableCell>
                  <Link
                    target="_blank"
                    color="inherit"
                    component={RouterLink}
                    to={
                      '/events/customer_events/' +
                      entry.customerEventId +
                      '/devices'
                    }
                    variant="h6">
                    {entry.venueName}
                  </Link>
                </TableCell>
                <TableCell>
                  <NumberFormat value={entry.associatedCellCount} />
                </TableCell>
              </>
            )}
            <TableCell>
              <NumberFormat value={entry.walkCount} />
            </TableCell>
            <TableCell>
              <NumberFormat
                value={entry.noObjectRadarAverageScore}
                percentage={true}
                suffix={'%'}
                fixedDecimalScale={true}
                precision={2}
              />
            </TableCell>
            <TableCell>
              <NumberFormat
                value={entry.noObjectRadarAccuracy}
                percentage={true}
                suffix={'%'}
                fixedDecimalScale={true}
                precision={2}
              />
            </TableCell>
            <TableCell>
              <NumberFormat
                value={entry.noObjectMagnetometerAccuracy}
                percentage={true}
                suffix={'%'}
                fixedDecimalScale={true}
                precision={2}
              />
            </TableCell>
            <TableCell>
              <NumberFormat value={entry.torsoObjectWalkCount} />
            </TableCell>
            <TableCell>
              <NumberFormat
                value={entry.objectRadarAccuracy}
                percentage={true}
                suffix={'%'}
                fixedDecimalScale={true}
                precision={2}
              />
            </TableCell>
            <TableCell>
              <NumberFormat value={entry.ankleObjectWalkCount} />
            </TableCell>
            <TableCell>
              <NumberFormat
                value={entry.objectMagnetometerAccuracy}
                percentage={true}
                suffix={'%'}
                fixedDecimalScale={true}
                precision={2}
              />
            </TableCell>
            <TableCell>
              <NumberFormat value={entry.magnetometerDeactivatedCount} />
            </TableCell>
            <TableCell>
              {entry.thresholds?.map(t => (t * 100).toFixed(0)).join(', ')}
            </TableCell>
            <TableCell>{entry.models?.join(', ')}</TableCell>
            <TableCell>
              <NumberFormat value={entry.averageWeatherTemperatureFahrenheit} />
            </TableCell>
            <TableCell>
              <NumberFormat value={entry.averageWeatherWindSpeedMph} />
            </TableCell>
            <TableCell>
              <NumberFormat value={entry.averageWeatherPrecipitationIn} />
            </TableCell>
            <TableCell>{entry.softwareVersions?.join(', ')}</TableCell>
          </TableRow>
        ))}
        {reportEntries.length === 0 && (
          <TableRow>
            <TableCell colSpan={17}>No data available</TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
};

export default WalkMonitoringReportEntryListTable;
