import { report } from 'process';
import ws from '../utils/ws';

/* Actions */

export const cellLogEntryPaginatedList = (pageNumber, pageSize) => dispatch =>
  dispatch({
    type: CELL_LOG_ENTRIES_PAGINATED_LIST,
    payload: ws.get(`/cell_log_entries/page/${pageNumber}/rows/${pageSize}`)
  });

export const findCellLogEntries = cellId => dispatch =>
  dispatch({
    type: FIND_CELL_LOG_ENTRIES,
    payload: ws.get(`/cells/${cellId}/log_entries/`)
  });

export const findCellLogEntry = id => dispatch =>
  dispatch({
    type: FIND_CELL_LOG_ENTRY,
    payload: ws.get(`/cells/log_entries/${id}`)
  });

export const findCellLogEntrySummaryCounts = reportFinishTime => dispatch =>
  dispatch({
    type: FIND_CELL_LOG_ENTRY_SUMMARY_COUNTS,
    payload: ws.get(
      `/cell_log_entries/cell_summary_counts/?reportFinishTime=${reportFinishTime}`
    )
  });

/* Constants */

export const CELL_LOG_ENTRIES_PAGINATED_LIST =
  'CELL_LOG_ENTRIES_PAGINATED_LIST';
export const CELL_LOG_ENTRIES_PAGINATED_LIST_PENDING =
  'CELL_LOG_ENTRIES_PAGINATED_LIST_PENDING';
export const CELL_LOG_ENTRIES_PAGINATED_LIST_FULFILLED =
  'CELL_LOG_ENTRIES_PAGINATED_LIST_FULFILLED';

export const FIND_CELL_LOG_ENTRIES = 'FIND_CELL_LOG_ENTRIES';
export const FIND_CELL_LOG_ENTRIES_PENDING = 'FIND_CELL_LOG_ENTRIES_PENDING';
export const FIND_CELL_LOG_ENTRIES_FULFILLED =
  'FIND_CELL_LOG_ENTRIES_FULFILLED';

export const FIND_CELL_LOG_ENTRY = 'FIND_CELL_LOG_ENTRY';
export const FIND_CELL_LOG_ENTRY_PENDING = 'FIND_CELL_LOG_ENTRY_PENDING';
export const FIND_CELL_LOG_ENTRY_FULFILLED = 'FIND_CELL_LOG_ENTRY_FULFILLED';

export const FIND_CELL_LOG_ENTRY_SUMMARY_COUNTS =
  'FIND_CELL_LOG_ENTRY_SUMMARY_COUNTS';
export const FIND_CELL_LOG_ENTRY_SUMMARY_COUNTS_PENDING =
  'FIND_CELL_LOG_ENTRY_SUMMARY_COUNTS_PENDING';
export const FIND_CELL_LOG_ENTRY_SUMMARY_COUNTS_FULFILLED =
  'FIND_CELL_LOG_ENTRY_SUMMARY_COUNTS_FULFILLED';
